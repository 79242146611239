import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import $ from "jquery";
import "./Card.css";
import { initiateSub } from "../../services/sync";
import { toast } from "../../helpers/apiRequests";
import { getMe } from "../../services/auth";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import countriesList from "./countries.json";
import { Select } from "@material-ui/core";



const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    "@media (max-width: 600px)": {
      width: "90% !important",
    }
  },
  loader: {
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2,
    display: "grid",
    placeItems: "center",
  },
}));

function changeImpactedElement(tagId, removeClass, addClass) {
  removeClass = removeClass || "";
  addClass = addClass || "";
  $("[data-bluesnap=" + tagId + "]")
    .removeClass(removeClass)
    .addClass(addClass);
}

const bluesnapSetUp = (bluesnapToken) => {
  const script = document.createElement("script");
  script.src = "https://bluesnap.com/web-sdk/4/bluesnap.js";

  // cardUrl: object that stores card type code (received from BlueSnap) and associated card image URL
  const cardUrl = {
    AMEX: "https://files.readme.io/97e7acc-Amex.png",
    DINERS: "https://files.readme.io/8c73810-Diners_Club.png",
    DISCOVER: "https://files.readme.io/caea86d-Discover.png",
    JCB: "https://files.readme.io/e076aed-JCB.png",
    MASTERCARD: "https://files.readme.io/5b7b3de-Mastercard.png",
    VISA: "https://files.readme.io/9018c4f-Visa.png",
  };

  const bsObj = {
    //insert your Hosted Payment Fields token
    token: bluesnapToken,
    onFieldEventHandler: {
      onFocus: function (tagId) {
        // Handle focus
        changeImpactedElement(tagId, "", "hosted-field-focus");
      },
      onBlur: function (tagId) {
        // Handle blur
        changeImpactedElement(tagId, "hosted-field-focus");
      },
      onError: function (tagId, errorCode, errorDescription) {
        // Handle a change in validation by displaying help text
        $("#" + tagId + "-help")
          .removeClass("helper-text-green")
          .text(errorDescription);
      },
      onType: function (tagId, cardType, cardData) {
        // get card type from cardType and display card image
        $("#card-logo > img").attr("src", cardUrl[cardType]);
        if (null != cardData) {
          $("#" + tagId + "-help")
            .addClass("helper-text-green")
            .text("Okay.");
        }
      },
      onValid: function (tagId) {
        // Handle a change in validation by removing any help text
        $("#" + tagId + "-help").text("");
      },
    },
    style: {
      // Styling all inputs
      input: {
        "font-size": "14px",
        "font-family":
          "RobotoDraft,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif",
        "line-height": "1.42857143",
        color: "#555",
      },
      // Styling input state
      ":focus": {
        color: "#555",
      },
    },
    ccnPlaceHolder: "1234 5678 9012 3456",
    cvvPlaceHolder: "123",
    expPlaceHolder: "MM / YY",
  };
  script.onload = () => {
    window.bluesnap.hostedPaymentFieldsCreate(bsObj);
  };
  document.body.appendChild(script);
};

const AddCardComponent = (props) => {

  const classes = useStyles();
  const [bluesnapToken, setBlueSnapToken] = useState(props.bluesnapToken);
  const [isLoading, setIsloading] = useState(false);
  const history = useHistory();
  const user = useSelector((state) => state.user.authUser);

  const initiateSubscription = async (cardDetails) => {
    setIsloading(true)
    try {
      let payload = {
        plan_id: props.plainId,
        ...cardDetails
      };
      await initiateSub(payload);
      toast.notify("Payment initiated successfully", { type: "success" });

      if (!user.podio_id) {
        history.push("/connect");
      } else {
        history.push("/dashboard/sync");
      }

    } catch (error) {
      toast.notify(error?.message, { type: "error" });
    } finally {
      setIsloading(false); // Stop loading spinner
    }
  };

  /* Calling bluesnap.submitCredentials: function that submits card data to
  BlueSnap and calls input function with card data object if submission was successful */
  // function submitCard(e) {
  //   e.preventDefault();

  //   window.bluesnap.hostedPaymentFieldsSubmitData(function (callback) {
  //     const cardData = callback.cardData;
  //     const name = $("#cardholder-name").val();
  //     const payload = {
  //       name,
  //       email: props.user.email,
  //       bluesnap_token: bluesnapToken,
  //       last_four_digit: cardData.last4Digits,
  //       card_brand: cardData.ccType,
  //       card_expire: cardData.exp,
  //     };

  //     if (
  //       !name ||
  //       props.user.email === "" ||
  //       cardData.last4Digits === "" ||
  //       cardData.ccType === "" ||
  //       cardData.exp === ""
  //     ) {
  //       toast.notify("Please enter all card details");
  //     }

  //     if (null != callback.error) {
  //       const errorArray = callback.error;
  //       for (const i in errorArray) {
  //         $("#" + errorArray[i].tagId + "-help").text(
  //           errorArray[i].errorCode + " - " + errorArray[i].errorDescription
  //         );
  //       }
  //       return;
  //     }
  //     // setIsloading(true);
  //     // saveCard(payload)
  //     //   .then((res) => {
  //     //     setIsloading(false);
  //     //     const response = res;
  //     //     if (response.message) {
  //     //       setIsloading(false);
  //     //       toast.notify("Card saved successfully!", {
  //     //         type: "success"
  //     //       });
  //     //     }
  //     //     initiateSubscription();
  //     //     setIsloading(false);
  //     //   })
  //     //   .catch((error) => {
  //     //     console.log(error)
  //     //     toast("Failed to add card!", {
  //     //       type: "error"
  //     //     });
  //     //     setIsloading(false);
  //     //   });

  //     try {
  //       setIsloading(true);
  //       const response = saveCard(payload);
  //       console.log(response)
  //       // if (response.message) {
  //       initiateSubscription();
  //       toast.notify("Card saved successfully!", {
  //         type: "success"
  //       });
  //       // }
  //       setIsloading(false);
  //     } catch (error) {
  //       setIsloading(false);
  //       console.log(error)
  //       toast("Failed to add card!", {
  //         type: "error"
  //       });
  //     }
  //   });
  // }

  async function submitCard(e) {
    e.preventDefault();

    window.bluesnap.hostedPaymentFieldsSubmitData(async function (callback) {
      const cardData = callback.cardData;
      const name = $("#cardholder-name").val();
      const payload = {
        name: name,
        email: props.user.email,
        bluesnap_token: bluesnapToken,
        last_four_digit: cardData?.last4Digits,
        card_brand: cardData?.ccType,
        card_expire: cardData?.exp,
        address: {
          address: $("#billing-address").val(),
          city: $("#billing-city").val(),
          state: $("#billing-state").val(),
          country: $("#billing-country").val(),
          zip_code: $("#billing-zip-code").val(),
        }
      };

      // Validate input
      if (
        !payload.name ||
        !props.user.email ||
        !cardData?.last4Digits ||
        !cardData?.ccType ||
        !cardData?.exp ||
        !payload.address?.address ||
        // !payload.address?.city ||
        // !payload.address?.state ||
        !payload.address?.country ||
        !payload.address?.zip_code
      ) {
        toast.notify("Please enter all card details", { type: "info" });
        return;
      }

      // Handle Bluesnap errors
      if (callback.error) {
        const errorArray = callback.error;
        for (const i in errorArray) {
          $("#" + errorArray[i].tagId + "-help").text(
            `${errorArray[i].errorCode} - ${errorArray[i].errorDescription}`
          );
        }
        return;
      }

      try {
        await initiateSubscription(payload);
      } catch (error) {
        toast.notify(error?.message, { type: "error" });
      }
    });
  }


  const updateBlueSnapToken = React.useCallback(async () => {
    function getProfile() {
      getMe()
        .then((me) => {
          setBlueSnapToken(me.data.bluesnap_token);
        })
        .catch((e) => {
          toast.notify("An error occurred, try refreshing this page", {
            type: "error",
          });
        });
    }
    await getProfile();
  }, []);

  React.useEffect(() => {
    updateBlueSnapToken();
  }, [updateBlueSnapToken]);

  React.useEffect(() => {
    bluesnapSetUp(bluesnapToken);
  }, [bluesnapToken]);

  return (
    <>
      <div className={classes.paper}>
        <div className="card-name mt-3">
          <label className="mb-2" htmlFor="name">
            Card Holder*
          </label>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="cardholder-name"
            placeholder="Full Name"
            name="name"
            autoComplete="name"
          />
        </div>

        <div className="card-number">
          <div className="div-inner">
            <label className="mb-2" htmlFor="cvv">
              Card Number
            </label>
            <div
              className="hosted-textfield div-inner"
              id="card-number"
              data-bluesnap="ccn"
            />
            <span className="helper-text" id="ccn-help" />
          </div>

          <div id="card-logo" className="card-logo mt-3">
            <img
              src="https://files.readme.io/d1a25b4-generic-card.png"
              height="30px"
              alt="Sync"
            />
          </div>
        </div>

        <div className="card-extras">
          <div className="div-inner pr-md-4">
            <label className="mb-2" htmlFor="exp-date">
              Exp. (MM/YY)
            </label>
            <div
              className="hosted-textfield"
              id="exp-date"
              data-bluesnap="exp"
            />
            <span className="helper-text" id="exp-help" />
          </div>

          <div>
            <label className="mb-2" htmlFor="cvv">
              CVV
            </label>
            <div
              className="hosted-textfield"
              id="card-number"
              data-bluesnap="cvv"
            />
            <span className="helper-text" id="cvv-help" />
          </div>
        </div>
        <hr />
        <h4
          style={{ fontSize: "16px", fontWeight: "600" }}
          id="transition-modal-title"
        >
          Billing Information:
        </h4>
        <div className="row">
          <div className="mt-3 col-md-8">
            <label className="mb-2" htmlFor="address">
              Address*
            </label>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="billing-address"
              placeholder="House 5, Street 6, Area 7"
              name="address"
              autoComplete="address"
            />
          </div>
          <div className="mt-3 col-md-4">
            <label className="mb-2" htmlFor="address">
              Zip Code*
            </label>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="billing-zip-code"
              placeholder="CT17 9JY"
              name="postcode"
              autoComplete="postcode"
            />
          </div>
          <div className="mt-3 col-md-4">
            <label className="mb-2" htmlFor="address">
              City
            </label>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="billing-city"
              placeholder="Middletown"
              name="city"
              autoComplete="city"
            />
          </div>
          <div className="mt-3 col-md-4">
            <label className="mb-2" htmlFor="address">
              State
            </label>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="billing-state"
              placeholder="Delaware"
              name="address"
              autoComplete="address"
            />
          </div>

          <div className="mt-3 col-md-4">
            <label className="mb-2" htmlFor="address">
              Country*
            </label>
            <Select
              variant="outlined"
              required
              fullWidth
              id="billing-country"
              placeholder="United States"
              name="country"
              native={true}
              children={countriesList.map((country) => ( <option key={country.code} value={country.code}>{country.name}</option>))}
              autoComplete="country"
            />
          </div>
        </div>
        
        <hr className="mt-4"/>

        <label className="mt-4 d-flex align-items-start mb-5">
          <input
            style={{
              appearance: "auto",
              width: "24px",
              marginTop: "5px",
              height: "auto",
              accentColor: "#F7C948",
            }}
            type="checkbox"
            checked={props.isChecked}
            onChange={props.handleCheckboxChange}
          />
          <p className="mb-0 ml-2">
            By checking this box, you are agreeing to our{" "}
            <a
              style={{ color: "#F7C948" }}
              href="https://help.thatapp.io/en/articles/9392123-terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of service
            </a>
            ,{" "}
            <a
              style={{ color: "#F7C948" }}
              href="/pricing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Pricing
            </a>{" "}
            and{" "}
            <a
              style={{ color: "#F7C948" }}
              href="https://help.thatapp.io/en/articles/108584-privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy{" "}
            </a>
          </p>
        </label>

        <section style={{ display: 'flex', alignItems: "center", justifyContent: "space-between", width: "100%" }} >
          <button
            className="generate_btn"
            style={{
              backgroundColor: "#fff",
              border: "1px solid black",
              color: "#000",
            }}
            onClick={() => history.goBack()}
          >
            Cancel
          </button>

          {isLoading ? (
            <div
              style={{ float: "right" }}
              className="flex justify-content-end"
            >
              <button className="generate_btn">
                <CircularProgress sx={{ color: "white" }} size={20} />
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              <button
                onClick={(e) => submitCard(e)}
                className=""
                style={{
                  backgroundColor: props.isChecked === false ? "#d3d3d3" : "#de911d",
                  color: props.isChecked === false ? "#a9a9a9" : "#FFFFFF",
                  border: "none",
                  cursor: props.isChecked === false ? "not-allowed" : "pointer",
                }}
                disabled={props.isChecked === false}
              >
                Activate Billing
              </button>

            </div>
          )}
        </section>

      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.authUser,
});

export default connect(mapStateToProps, null)(AddCardComponent);
