import axios from "axios";
import appendTeamMemberHeader from "utils/appendTeamMemberHeader";
import handleResponseError from "./utils/handleResponseError";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: { "Content-Type": "application/json" },
});

// Add a request interceptor
Axios.interceptors.request.use(
  function (config) {
    // Dynamically set Authorization header
    const authToken = localStorage.getItem("chosen_token");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    // Append additional headers dynamically
    config.headers = appendTeamMemberHeader(config.headers);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
Axios.interceptors.response.use(
  function (response) {
    return response.data;
  },
  async function (error) {
    const status = parseInt(error?.response?.status);
    if (status === 401) {
      // Clear local storage and redirect on unauthorized access
      localStorage.clear();
      sessionStorage.clear(); // If applicable
      window.location.href = "/login";
    }
    handleResponseError(error.response);
    return Promise.reject(error);
  }
);

export default Axios;
